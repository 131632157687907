import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import CurrentOpportunities, { OpportunitiesContent, OpportunitiesLinks, OpportunitiesCallouts } from '~2-components/CurrentOpportunities/CurrentOpportunities';
import { currentOpportunitiesData } from '~data/currentOpportunitiesData';
import CurrentOpportunitiesKontent from '~3-data-components/CurrentOpportunitiesKontent';
import OpportunityItem from '~2-components/CurrentOpportunities/OpportunityItem';
import CallUsCallout from '~2-components/CallUsCallout/CallUsCallout';

const CurrentOpportunitiesEg = `import CurrentOpportunities from '~2-components/CurrentOpportunities/CurrentOpportunities';

<CurrentOpportunities>
    <OpportunitiesContent>
        // Child content goes here
    </OpportunitiesContent>
    <OpportunitiesLinks>
        // Child Opportunity Items go here
    </OpportunitiesLinks>
    <OpportunitiesCallouts>
        // Child CallUsCallouts go here
    </OpportunitiesCallouts>
</CurrentOpportunities>
`;

const SgCurrentOpportunities = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allCurrentOpportunities = useStaticQuery(
        graphql`
            query SGAllCurrentOpportunities {
                allKontentItemCurrentOpportunities {
                    nodes {
                        system {
                            id
                        }
                        elements {
                            contact_person_details {
                                modular_content {
                                    system {
                                        id
                                    }
                                }
                            }
                            current_opportunities_content {
                                value
                            }
                            current_opportunities_list {
                                value {
                                    ... on kontent_item_general_content {
                                        elements {
                                            page_slug {
                                                value
                                            }
                                            title {
                                                value
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
    `);

    const allOpportunities = allCurrentOpportunities.allKontentItemCurrentOpportunities.nodes;

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Current Opportunities</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={CurrentOpportunitiesEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <CurrentOpportunities>
                        <OpportunitiesContent>
                            <h3>Current Opportunities</h3>
                            <p>If you are looking to take the next step in your career, we want to hear from you.</p>
                        </OpportunitiesContent>
                        <OpportunitiesLinks>
                            {currentOpportunitiesData.currentOpportunities.map((item, i) => {
                                return (
                                    <OpportunityItem
                                        key={i}
                                        opportunityText={item.opportunityText}
                                        linkUrl={item.linkUrl}
                                    />
                                );
                            })}
                        </OpportunitiesLinks>
                        <OpportunitiesCallouts>
                            <CallUsCallout>
                                <p>Call us on <strong>(03) 9321 9988</strong> and <strong>book your free appointment</strong> for preliminary advice about your rights and possible entitlements.</p>
                            </CallUsCallout>
                        </OpportunitiesCallouts>
                    </CurrentOpportunities>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>All LIVE Kontent current opportunities</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    {allOpportunities.map((item, i) => {
                        return <CurrentOpportunitiesKontent key={i} {...item}/>;
                    })}
                </div>
            </section>
        </SgLayout>
    );
};

export default SgCurrentOpportunities;
