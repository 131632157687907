export const currentOpportunitiesData = {
    currentOpportunities: [
        {
            opportunityText: 'Legal Assistant - Melbourne',
            linkUrl: '/a-page',
        },
        {
            opportunityText: 'Another Legal Assistant - Sydney',
            linkUrl: '/another-page',
        },
        {
            opportunityText: 'Opportunity with a very long title - Adelaide',
            linkUrl: '/third-page',
        },
    ],
};
